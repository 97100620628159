/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import cn from 'classnames';
import { METAL_FRAME_COLORS, PRODUCT_TYPES, TOAST } from '@constants';
import {
	BaseFramePDPDetails,
	BaseFramePDPSidebar,
	BuildFlow,
	Carousel,
	ErrorBoundary,
	FakeRecommendationCarousel,
	ImageGallery,
	Modal,
	ProductView,
	StepTracker,
	VTO,
} from '@components';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	FRAME_COLOR_HANDLES,
	FRAME_COLORS,
	generateSunglassImages,
	getVariantByOption,
	LARGE_WIDTH,
	LOCALE_DICT,
	NEW_BASE_FRAME_NAMES,
	useCollectionsQuery,
	useIsMobile,
	VTO_SRC,
} from '@utils/index';
import { baseFrameReducer, BaseFrameState } from '@utils/hooks/useBaseFrame';
import { useTailoredExperience } from '@services/contentful';
import { trackProductView } from '@services/analytics/trackers';
import { useDebounce, useHasMounted, useLockedBody } from '@utils/hooks';
import { useBFContext, useToastContext } from '@context';
import { CollectionListFields } from '@ts/contentful';
import styles from './BaseFramePDP.module.scss';

const YotpoReviews = dynamic(() => import('@components').then(mod => mod.YotpoReviews));

const BaseFramePDP = ({ journey, lifestyleImages, yotpo, seoMetadata = {} }) => {
	const {
		collectionListSlug,
		setCollectionListSlug,
		frame,
		frameColor,
		lensColor,
		setVariantImages,
		product,
		step,
		setFrameColor,
		isQuickAdd,
		setStepChange,
	} = useBFContext();
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const isDoubleColImage = useFeatureIsOn('is-double-col-image');
	const isSingleColImage = useFeatureIsOn('is-single-col-image');
	const imageLayoutType = isDoubleColImage ? 'double' : isSingleColImage ? 'single' : 'none';

	const [images, setImages] = useState([]);
	const { query, beforePopState, locale, push } = useRouter();
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);
	const isVTOonCarouselImage = useFeatureIsOn('is-vto-on-carousel-image');
	const isStickySidebar = useFeatureIsOn('is-sticky-right-rail');

	const hasMounted = useHasMounted();
	const { showToast } = useToastContext();
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const isEditMode = !isQuickAdd ? Boolean(query?.edit) : Boolean(query?.edit) && !Boolean(query?.quickAdd);

	// Product Info
	const { name, tags, type, variantImages } = product;

	const selectedVariant = getVariantByOption(product, frameColor);

	const initialVtoState: BaseFrameState = {
		product: product,
		variant: product.variants[0],
		frame: product.variants[0].option as FRAME_COLORS,
		lens: null,
		image: product.variants[0].image.url,
	};

	// const isMetalFrameColor = [FRAME_COLORS.MIX_BLACK_GOLD, FRAME_COLORS.MIX_CRYSTAL_SILVER].includes(frameColor);
	const isMetalFrameColor = METAL_FRAME_COLORS.includes(frameColor);
	const productVideo = selectedVariant?.metafields?.productVideo;
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);
	const [selectedMaterial, setSelectedMaterial] = useState(isMetalFrameColor ? frameColor : 'Acetate');
	const [modalType, setModalType] = useState<'VTO' | 'Imagery'>('VTO');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const showVideo = query.demo === 'women' && !SUNGLASSES_JOURNEY;

	const { data: tailoredExperience } = useTailoredExperience();
	if (!!tailoredExperience?.collectionList) {
		setCollectionListSlug((tailoredExperience.collectionList?.fields as CollectionListFields)?.slug);
	}

	useCollectionsQuery(collectionListSlug, {
		queryKey: `build-flow-collections-${frame}`,
		queryRefreshVars: [collectionListSlug, locale],
		type: 'buildflow',
		withProducts: true,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [
			{
				name: 'Frame',
				value: frame,
			},
		],
		country,
		language,
	});

	const isBuildFlowOpen = isQuickAdd ? step > BUILD_FLOW_STEPS.CUSTOMIZE : step >= BUILD_FLOW_STEPS.LENS;
	const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
	isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');

	const debouncedTrackProductView = useDebounce(trackProductView);
	useEffect(() => {
		debouncedTrackProductView({
			variant: selectedVariant,
			path: `/${journey}/${query.demo}/`,
		});
	}, [selectedVariant]);

	const sunglassImageCallback = useCallback(({ name, baseColor, lensColor, length }) => {
		return generateSunglassImages({ name, baseColor, lensColor, length });
	}, []);

	const hideLifeStyleImages =
		frame === NEW_BASE_FRAME_NAMES.HARPER &&
		(frameColor === FRAME_COLORS.HONEY_CLEAR ||
			frameColor === FRAME_COLORS.LAVENDER_CLEAR ||
			(SUNGLASSES_JOURNEY && frameColor === FRAME_COLORS.GRAY_CLEAR));

	const frameImages = useMemo(
		() =>
			SUNGLASSES_JOURNEY
				? sunglassImageCallback({ name, baseColor: frameColor, lensColor: lensColor, length: 3 })
				: variantImages[frameColor],
		[product, frameColor, lensColor]
	);

	useLockedBody(isBuildFlowOpen);

	useEffect(() => {
		const checkImage = (url: string): Promise<boolean> =>
			new Promise(resolve => {
				const img = new Image();
				img.onload = () => resolve(true);
				img.onerror = () => resolve(false);
				img.src = url;
			});

		const getModifiedUrl = (baseUrl: string): string => {
			const frameColorHandle = Object.entries(FRAME_COLORS).find(([, value]) => value === frameColor)?.[0];
			const handleValue = FRAME_COLOR_HANDLES[frameColorHandle];
			return baseUrl.replace('.png', `-${handleValue}.png`);
		};

		const updateImages = async () => {
			const validImages = [];

			for (const lifestyleImage of lifestyleImages.carousel) {
				const originalUrl = lifestyleImage.url;
				const modifiedUrl = getModifiedUrl(originalUrl);

				const urlToUse = (await checkImage(modifiedUrl)) ? modifiedUrl : originalUrl;
				if (!(await checkImage(urlToUse))) continue;
				validImages.push({ ...lifestyleImage, url: urlToUse });
			}

			// hardcode to hide lifestyle images for Harper Lavender and Honey for now
			hideLifeStyleImages
				? setImages([...frameImages, lifestyleImages.carousel[0]])
				: setImages([...frameImages, ...validImages]);
		};

		updateImages();
	}, [frameColor, lifestyleImages, frameImages, isMetalFrameColor]);

	useEffect(() => {
		setVariantImages(frameImages.slice(0, -1));
	}, [frameImages]);

	useEffect(() => {
		if (isEditMode) showToast(TOAST.EDIT_MODE);
	}, [isEditMode]);

	useEffect(() => {
		const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
		isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');
	}, [step]);

	useEffect(() => {
		if (isMetalFrameColor) {
			setSelectedMaterial(frameColor);
		} else {
			setSelectedMaterial('Acetate');
		}
	}, [frameColor]);

	useMemo(() => {
		beforePopState(() => {
			header.style.display = '';
			return true;
		});
	}, []);

	useEffect(() => {
		if (isQuickAdd && !query?.quickAdd && isBuildFlowOpen) {
			setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
		}
	}, [isQuickAdd, query]);

	const Recommendations = useCallback(
		() => (
			<ErrorBoundary>
				<FakeRecommendationCarousel
					variant={selectedVariant}
					selectedFrame={frame}
					headingText={`Similar to ${name}`}
					productTypes={[PRODUCT_TYPES.BASE_FRAME]}
					demo={query.demo as string}
					withPadding={isStickySidebar}
				/>
			</ErrorBoundary>
		),
		[selectedVariant, frame, name, query.demo, isStickySidebar]
	);

	if (!hasMounted) return;

	return (
		<Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
			{!isBuildFlowOpen ? (
				<ProductView.Root
					seo={{
						...seoMetadata,
						product: {
							...product,
							aggregateRating: yotpo,
							images: frameImages,
							suggestedGender: query.demo as string,
							variants: [selectedVariant],
						},
					}}
					isStickySidebar={isStickySidebar}
				>
					<ProductView.Images>
						<div className={cn({ [styles['left-rail-container']]: isQuickAdd && !isStickySidebar })}>
							<StepTracker />
							{(isSingleColImage || isDoubleColImage) && !isMobile ? (
								<ImageGallery
									images={images}
									video={productVideo}
									name={name}
									product={product}
									tags={tags}
									type={type}
									variant={selectedVariant}
									imageLayoutType={imageLayoutType}
									VTODispatch={isVTOonCarouselImage && !SUNGLASSES_JOURNEY && setVtoState}
								/>
							) : (
								<Carousel
									images={images}
									isModalOpen={isModalOpen}
									video={showVideo && productVideo}
									name={name}
									product={product}
									tags={tags}
									type={type}
									setModalType={setModalType}
									selectedImgIndex={selectedImgIndex}
									setSelectedImgIndex={setSelectedImgIndex}
									variant={selectedVariant}
									VTODispatch={isVTOonCarouselImage && !SUNGLASSES_JOURNEY && setVtoState}
								/>
							)}
						</div>

						{isStickySidebar && !isMobile && (
							<>
								<BaseFramePDPDetails journey={journey} selectedMaterial={selectedMaterial} padding='small' />
								<Recommendations />
							</>
						)}
					</ProductView.Images>
					<BaseFramePDPSidebar
						frameImages={frameImages}
						yotpo={yotpo}
						journey={journey}
						VTODispatch={!SUNGLASSES_JOURNEY && setVtoState}
						selectedMaterial={selectedMaterial}
						setSelectedMaterial={setSelectedMaterial}
						setModalType={setModalType}
						isStickySidebar={isStickySidebar}
					/>
					{(!isStickySidebar || isMobile) && (
						<>
							<BaseFramePDPDetails journey={journey} selectedMaterial={selectedMaterial} />
							<ProductView.Recommendations>
								<Recommendations />
							</ProductView.Recommendations>
						</>
					)}
					<ProductView.Reviews>
						<YotpoReviews product={product} />
					</ProductView.Reviews>
				</ProductView.Root>
			) : (
				<BuildFlow />
			)}
			{!SUNGLASSES_JOURNEY && modalType === 'VTO' && (
				<>
					<Modal.Content
						removePadding
						includeCloseButton={false}
						onCloseAutoFocus={e => e.preventDefault()}
						style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
					>
						<VTO
							FM={FitMix}
							state={vtoState}
							setState={setVtoState}
							pathInfo={`/${journey}/${query.demo}`}
							showBaseFrameOption={false}
							selectedMaterial={selectedMaterial}
							showMaterialOption
							setSelectedMaterial={setSelectedMaterial}
							setFrameColor={frameColor => {
								setFrameColor(frameColor);
								push({ query: { ...query, frameColor: encodeURI(frameColor as string) } }, undefined, {
									shallow: true,
								});
							}}
						/>
					</Modal.Content>
					<Script
						src={VTO_SRC}
						strategy='lazyOnload'
						onLoad={() => {
							setFitMix(window.FitMix);
						}}
					></Script>
				</>
			)}
		</Modal>
	);
};

export default BaseFramePDP;
