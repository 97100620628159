/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ProductView } from '@components';
import {
	BADGE_TYPES,
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	COLORS_SWAP_MAP,
	CUSTOMER_JOURNEYS,
	FRAME_COLORS,
	LOCALE_CODES,
	MIX_MATERIAL_SWATCHES_OPTIONS,
	SUBMISSION_METHODS,
} from '@constants';
import { useBFContext } from '@context';
import { getVariantByOption, updateProductVariantPrice } from '@utils/shopify';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import {
	useBaseFrameCartManager,
	useDiscountedPrice,
	useIsProductVariantSoldOut,
	useIsScrolled,
	useIsVipMembership,
} from '@utils/hooks';
import { sendHeapEventFlag } from '@services/analytics/heap';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import styles from './BaseFramePDPSidebar.module.scss';

const BaseFramePDPSidebar = ({
	journey,
	yotpo,
	frameImages,
	VTODispatch,
	selectedMaterial,
	setSelectedMaterial,
	setModalType,
	isStickySidebar = false,
}) => {
	const { asPath, push, query, locale } = useRouter();
	const {
		product,
		subtotal,
		frameColor,
		setFrameColor,
		lensColor,
		setStepChange,
		setSelectedLenses,
		variantPrices,
		frame,
		readerRx,
		previousBaseFrames,
		rxType,
		lensPackage,
		basePriceWithoutLens,
		isSubscriptionActivated,
		isQuickAdd,
		BaseFrameVariant,
	} = useBFContext();
	const {
		description,
		name,
		type,
		variantImages,
		metafields: { frameSizings, lensColors },
	} = product;
	const { checkIfSoldOut } = useIsProductVariantSoldOut();
	const isScrolled = useIsScrolled();
	const isEditMode = Boolean(query?.edit);
	const quickAddLabel = !(rxType && lensPackage)
		? 'Select Required Fields'
		: isEditMode
			? 'Update Cart'
			: 'Add to Cart + Go to Tops';
	const controlLabel = isQuickAdd ? quickAddLabel : 'Build & Purchase';

	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const selectedVariant = getVariantByOption(product, frameColor);
	const variantDescription = selectedVariant?.description ?? description;
	const availableOptions = product.variants.map(variant => variant.option);
	const isSoldOut = checkIfSoldOut(product.handle, selectedVariant.option);

	const swatchOptions = selectedMaterial.includes('Mixed Material')
		? MIX_MATERIAL_SWATCHES_OPTIONS.filter(option => option.metal).map(option => option.metal)
		: MIX_MATERIAL_SWATCHES_OPTIONS.filter(option => availableOptions.includes(option.acetate)).map(option => option.acetate);

	const handleSwatchChange = useCallback(
		payload => {
			if (payload.type === 'lens') {
				push({ query: { ...query, lensColor: encodeURI(payload.option) } }, undefined, { shallow: true });
				setSelectedLenses([`Sun - ${payload.option}` as BASE_FRAME_LENS_OPTIONS]);
			}
			if (payload.type === 'frame') {
				push({ query: { ...query, frameColor: encodeURI(payload.option) } }, undefined, { shallow: true });
				setFrameColor(payload.option);
			}
		},
		[query]
	);

	const handleMaterialChange = useCallback(
		(material, frameColor) => {
			const acetateColor = COLORS_SWAP_MAP.Acetate[frameColor as FRAME_COLORS] ?? frameColor;
			push(
				{ query: { ...query, frameColor: material === 'Acetate' ? encodeURI(acetateColor) : encodeURI(material) } },
				undefined,
				{ shallow: true }
			);
			setSelectedMaterial(material);
			setFrameColor(material === 'Acetate' ? (acetateColor as FRAME_COLORS) : (frameColor as FRAME_COLORS));
		},
		[query, frameColor]
	);

	const isReApplyLensesTest1Active = useFeatureIsOn('is-re-apply-lenses-ux1-test');
	const isReApplyLensesTest2Active = useFeatureIsOn('is-re-apply-lenses-ux2-test');
	const isReApplyLensesTestActive = isReApplyLensesTest1Active || isReApplyLensesTest2Active;

	const dataTags = isReApplyLensesTestActive
		? {
				'data-reapply-lens-control': name,
				...(isReApplyLensesTest2Active && previousBaseFrames?.length > 0 && { 'data-reapply-lens-UX2-view': name }),
				...(isReApplyLensesTest1Active && previousBaseFrames?.length > 0 && { 'data-reapply-lens-UX1-view': name }),
			}
		: {};

	useEffect(() => {
		if (isReApplyLensesTestActive) {
			let variationId = 0;
			if (isReApplyLensesTest1Active && previousBaseFrames?.length > 0) {
				variationId = 1;
			}
			if (isReApplyLensesTest2Active && previousBaseFrames?.length > 0) {
				variationId = 2;
			}
			sendHeapEventFlag({ experimentId: 'bfro-reapply-build-flow-only-v2', variationId });
		}
	}, []);

	const [newPrice, newCompareAtPrice, isDiscountEligible] = useDiscountedPrice({
		price: basePriceWithoutLens,
		compareAtPrice: selectedVariant?.compareAtPrice?.amount,
		type: product.type,
		mandatoryDiscount: isSubscriptionActivated,
	});
	const { applyDiscountMembership, isMembershipActivated } = useIsVipMembership(type);

	const addBaseFrameToCart = useBaseFrameCartManager({
		editingBundleKey: (query[BUNDLE_KEY_QUERY] as string) ?? null,
		demo: query.demo as string,
		frame,
		frameColor,
		journey,
		lensColor,
		rxType,
		readerRx,
		product,
		submissionMethod: SUBMISSION_METHODS.REMIND,
		BaseFrameVariant,
		lensPackage,
		isBuildFlow: true,
		redirectToCart: false,
	});
	const priceTag = useMemo(() => {
		if (!applyDiscountMembership && isMembershipActivated && locale === LOCALE_CODES.US) return BADGE_TYPES.VIP_PROMO;
		if (isDiscountEligible) return BADGE_TYPES.MEMBERS_DISCOUNT;
		return null;
	}, [applyDiscountMembership, isDiscountEligible, isMembershipActivated, locale]);

	return (
		<div className={styles['base-frame-pdp-sidebar']}>
			<ProductView.Sidebar
				path={asPath}
				name={name}
				tag={applyDiscountMembership && BADGE_TYPES.VIP_DISCOUNT}
				subtotal={subtotal}
				productType={type}
				product={product}
				selectedVariant={updateProductVariantPrice(selectedVariant, newPrice, newCompareAtPrice)}
				priceTag={priceTag}
				yotpo={yotpo}
				primaryController={{
					controllerType: 'frame',
					title: 'Base Frame Color',
					selected: frameColor,
					options: swatchOptions,
					callback: handleSwatchChange,
					variantImages: variantImages,
				}}
				{...(SUNGLASSES_JOURNEY && {
					secondaryController: {
						controllerType: 'lens',
						title: 'Lens Color',
						selected: lensColor,
						options: lensColors.options,
						callback: handleSwatchChange,
						variantImages: undefined,
					},
				})}
				aboutProps={{
					description: variantDescription,
					frameSizings,
					name,
					primaryImage: frameImages[0],
					productType: type,
				}}
				buttonProps={{
					extraClasses: styles['cta-button'],
					availableForSale: product.availableForSale,
					dataTags: {
						'data-open-buildflow': true,
						'data-testid': 'buildAndPurchase',
						...(isQuickAdd && { 'data-go-to-step': BUILD_FLOW_STEPS[BUILD_FLOW_STEPS.TOP_FRAMES] }),
					},
					hasSubtotal: true,
					label: isSoldOut ? 'Sold out!' : controlLabel,
					disabled: isSoldOut || (isQuickAdd && !(rxType && lensPackage)),
					onClick: () => {
						setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
						if (isQuickAdd) {
							addBaseFrameToCart();
						}
					},
					withChevron: true,
					margin: '0',
				}}
				shipmentInfo={selectedVariant?.metafields?.shipmentInfo ?? null}
				VTODispatch={VTODispatch}
				isSunGlasses={SUNGLASSES_JOURNEY}
				selectedMaterial={selectedMaterial}
				handleMaterialChange={handleMaterialChange}
				previousBaseFrames={previousBaseFrames}
				variantPrices={variantPrices}
				journey={journey}
				readerRx={readerRx}
				frame={frame}
				setModalType={setModalType}
				isStickySidebar={isStickySidebar}
				isScrolled={isScrolled}
				{...dataTags}
			/>
		</div>
	);
};

export default BaseFramePDPSidebar;
